import React from 'react'
import ENV from '../env.json';

class Base extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_authenticated: false,
            is_loading: true,
            cognito_login: {},
            user: {}
        };

        this.checkLoginStatus = this.checkLoginStatus.bind(this);
    }
    checkLoginStatus = (e) => {
        this.setState({ is_authenticated: false });
        let url = ENV.ACCOUNTS_API_BASE_URI + "/auth/check/";
        // console.log("fetching", url);
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include"
        })
            .then((r) => { 
                // console.log(r); 
                return r.json() })
            .then((r) => {
                console.log("auth check returned response", r);
                this.setState({
                    "is_authenticated": r.is_authenticated,
                    "user": r.user,
                    "cognito_login": r.cognito_login,
                    "is_loading": false
                });
            })
    }

    componentDidMount() {
        this.checkLoginStatus();
    }

    render() {
        return (
            <div className="mainContainer">
                You are not logged in.
                <a hred="/">Return to Homepage</a>
            </div>
        )
    }
}

export default Base