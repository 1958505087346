import React from 'react'
import { useNavigate } from "react-router-dom";
import ENV from '../env.json';
import withRouter from '../withRouter'
import Base from './base';
import {OrbitProgress} from 'react-loading-indicators';

class Home extends Base {
    
    constructor(props) {
        super(props);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.openAssistant = this.openAssistant.bind(this);
    }

    onButtonClick = (e) => {
        if (this.state.is_authenticated != true) {
            window.location.href = ENV.ACCOUNTS_LOGIN_URI + '?next=' + ENV.REDIRECT_URI;
        } else {
            fetch(ENV.ACCOUNTS_API_BASE_URI + "/auth/logout/", {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include"
            })
                .then((r) => { console.log(r); return r.json() })
                .then((r) => {
                    console.log("returned response", r);
                        this.setState({
                            "is_authenticated": r.is_authenticated,
                            "cognito_login": r.cognito_login
                        })
                })
        }
        
    }

    openAssistant = (e) => {
        window.location.href = "/assistant";
    }

    render() {

        return (
            <div className="mainContainer">
                {this.state.is_loading ? 
                <div className="loading_container" >
                    <OrbitProgress color="#2688fa" size="medium" text="" textColor="" />
                </div>
                : null}
                <div className={'titleContainer'}>
                    <img src='/home_logo.png' alt='home logo' width="150" height="150" />
                    <div>Sensio Air Assistant</div>
                </div>
                {(this.state.is_authenticated) ? <div>You are logged in as '{this.state.user['username']}'</div>:<div>Please login to continue.</div>}
                <div className={'buttonContainer'}>
                    <input
                        className={'inputButton'}
                        type="button"
                        onClick={this.onButtonClick}
                        value={(this.state.is_authenticated) && 'Log out' || 'Log in'}
                    />
                    
                    {(this.state.is_authenticated) && 
                    <input
                        className={'inputButton'}
                        type="button"
                        onClick={this.openAssistant}
                        value="Continue"
                    />}
                </div>
                <div className={'footerContainer'}>
                © 2024 Sensio Air Inc. All rights reserved.
                </div>
            </div>
        )
    }
}

export default withRouter(Home)