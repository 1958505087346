import React from 'react'

import Base from './base'

import withRouter from '../withRouter'
import ENV from '../env.json';

import './assistant.css'

import { WebChatContainer, setEnableDebug } from '@ibm-watson/assistant-web-chat-react';
import { WebChatCustomElement } from '@ibm-watson/assistant-web-chat-react';

import {OrbitProgress} from 'react-loading-indicators';

// Include this if you want to get debugging information from this library. Note this is different than
// the web chat "debug: true" configuration option which enables debugging within web chat.
setEnableDebug(true);

class Home extends Base {
    constructor(props) {
        super(props);
        this.onAfterRender = this.onAfterRender.bind(this);

        this.instance = null;
        this.clickLogout = this.clickLogout.bind(this);
        this.openWebChat = this.openWebChat.bind(this);
    }
    
    onAfterRender(instance) {
        this.instance = instance;
        this.openWebChat();
    }

    openWebChat() {
        if (this.instance != null){
            this.instance.updateUserID("CORE:" + this.state.user.username);
            this.instance.changeView('mainWindow');
        }
    }

    clickLogout(e) {
        this.instance.closeWindow();
        window.location.href = '/'
    }
    componentDidMount() {
        super.componentDidMount();
        this.openWebChat();
    }

    render() {
        
        const webChatOptions = {
            integrationID: ENV.ASSISTANT_INTEGRATION_ID,
            region: ENV.ASSISTANT_REGION,
            serviceInstanceID: ENV.ASSISTANT_SERVICE_INSTANCE_ID,
            showLauncher: false,
            openChatByDefault: true,
            namespace: 'webchat',
            headerConfig: {
                showRestartButton: true
            },
            serviceDesk: {
                skipConnectAgentCard: true,
            },
            themeConfig: {
                // corners: 'square',
            },
            layout: {
                showFrame: true,
                hasContentMaxWidth: false,
            }
        };

        return (
            <div className="mainContainer">
                {this.state.is_loading ? 
                <div className="loading_container" >
                    <OrbitProgress color="#2688fa" size="medium" text="" textColor="" />
                </div>
                : null}
                <div className={"subContainer"} >
                    <div className="left-bar">
                        <div className="left-bar-container">
                            <div className='left-bar-element'>
                                <div className="webchat-logo" onClick={this.openWebChat}>
                                    <img src="/home_logo.png" alt="home logo" width="50" height="50" />
                                </div>
                            </div>
                            <div className='left-bar-element'></div>
                            <div className='left-bar-element'>
                                <div className="logout-button" onClick={this.clickLogout}>
                                    <img src='/logout.png' alt='logout' width="20" height="auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"webchat-container"}>
                        <WebChatCustomElement className="webchat" config={webChatOptions} onAfterRender={(e) => this.onAfterRender(e)} />
                    </div>
                </div>
                <div className={'footerContainer'}>
                    © 2024 Sensio Air Inc. All rights reserved.
                </div>
            </div>
        )
        

    }
}

export default withRouter(Home)