import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home'
import Assistant from './pages/assistant'
import './App.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index path="/"
            element={<Home />}
          />

          <Route path="/assistant"
            element={<Assistant />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;